import {Enum} from './utils'

const ScreenTypeEnum = new Enum(
  {
    alias: 'PORTRAIT',
    value: 0,
    text: '竖屏'
  },{
    alias: 'LANDSCAPE',
    value: 1,
    text: '横屏'
  }
)

const DeviceTypeEnum = new Enum(
  {
    alias: 'PC',
    value: 0,
    text: 'pc'
  },
  {
    alias: 'MOBILE',
    value: 1,
    text: '手机'
  }
)

const downloadUrlEnum = {
  Universal: 'https://file.campfires.cn/apk/tangdou_1.5.1.1003_guanwang_release.apk',
  qiuqiu: 'https://file.campfires.cn/apk/tangdou_1.5.1.1003_qiuqiu_release.apk',
  taikongsha: 'https://file.campfires.cn/apk/tangdou_1.5.1.1003_taikongsha_release.apk'
}

export {
  ScreenTypeEnum,
  DeviceTypeEnum,
  downloadUrlEnum
}